import './App.css';
import Header from "./Components/Header"
import {Button} from "reactstrap"
import{ToastContainer, toast} from "react-toastify";
import Home from './Components/home';
import Calculator from './Components/Calculator';

function App() {
  const btnHandle =()=>{
    toast.success("Submit",{
      position:'top-center'
    });
  };
  return (
   <div>
 <ToastContainer/>
 <Calculator/>
   </div>
  );
}

export default App;
